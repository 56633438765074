import {ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AmplifyService } from 'aws-amplify-angular';
import { Auth, Storage } from 'aws-amplify';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnDestroy {

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private amplifyService: AmplifyService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    Auth.currentCredentials();
    // Storage.put('bird/test.txt', 'Hello')
    // .then (result => console.log(result))
    // .catch(err => console.log(err));

    // Storage.put('pork/test.txt', 'Hello')
    // .then (result => console.log(result))
    // .catch(err => console.log(err));
    // Storage.configure({level: 'public'});
    // Storage.get('MVIMG_20181122_105029.jpg')
    // .then (result => console.log(result))
    // .catch(err => console.log(err));
  }

  selectedIndex: number = 0;
  public tabs = [
    {id: 0, label: 'Home' , class: 'welcome'},
    {id: 1, label: 'Beef' , class: 'beef'},
    {id: 2, label: 'Bird' , class: 'bird'},
    {id: 3, label: 'Pork' , class: 'pork'},
    {id: 4, label: 'Get to Know Us', class: 'get-to-know-us'}
  ];

  private tabsCount = this.tabs.length;

  selectChange(): void {
    console.log('Selected INDEX: ' + this.selectedIndex);
  }

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  // Action triggered when user swipes
  swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
  console.log('swipe');
  console.log('number', selectedIndex);
  console.log('action', action);
    // Out of range
  if (this.selectedIndex < 0 /* starter point as 1 */ || this.selectedIndex > this.tabsCount/* here it is */ ) { return; }

    // Swipe left, next tab
  if (action === this.SWIPE_ACTION.LEFT) {
      const isLast = this.selectedIndex === this.tabsCount;
      this.selectedIndex = isLast ? 0 : this.selectedIndex + 1;
      console.log('Swipe right - INDEX: ' + this.selectedIndex);
    }

    // Swipe right, previous tab
  if (action === this.SWIPE_ACTION.RIGHT) {
      const isFirst = this.selectedIndex === 0 /* starter point as 1 */;
      this.selectedIndex = isFirst ? 1 : this.selectedIndex - 1;
      console.log('Swipe left - INDEX: ' + this.selectedIndex);
    }
  }

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({length: 10}, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({length: 10}, () =>
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  // tslint:disable-next-line: variable-name
  private _mobileQueryListener: () => void;

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onAlbumImageSelected( event ) {
    window.open( event, '_blank' );
  }
}
