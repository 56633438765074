// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:640527a3-9246-4b65-bc8f-aecb42382aa3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PBVsOYGVd",
    "aws_user_pools_web_client_id": "3fbr1p1qs14d3dq9jnl6471rmf",
    "oauth": {},
    "aws_user_files_s3_bucket": "testangulars3-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
